<template>
  <Creation
    v-if="briefingServiceData"
    :briefing-service-data="briefingServiceData"
  />
</template>

<script lang="ts" setup>
  import './main.css'
  import { ref, onBeforeUnmount } from 'vue'
  import Creation from '@/components/calendarEntry/Creation.vue'
  import { BriefingServiceData } from '@/types'

  const briefingServiceData = ref<BriefingServiceData|null>(null)

  const handleMessage = (e: MessageEvent) => {
    if (e.data.command === 'briefingServicePostData') {
      briefingServiceData.value = e.data.content
    }
  }

  window.addEventListener('message', handleMessage)

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleMessage)
  })
</script>
