import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ics-header" }
const _hoisted_2 = { class: "ics-container" }
const _hoisted_3 = { class: "ics-content mt-6 mb-4 bg-primary-light bg-opacity-30 p-2 rounded-lg" }
const _hoisted_4 = { class: "ics-action-btn flex gap-x-2 justify-end" }

import {ref, watch, PropType, onMounted} from 'vue'
import {ScpButton, ScpProcessPagination, ScpModal} from '@rmh-media/scp-components'
import Information from '@/components/calendarEntry/Information.vue'
import DateAndTime from '@/components/calendarEntry/DateAndTime.vue'
import Location from '@/components/calendarEntry/Location.vue'
import {DYNAMIC_ICS_GENERATOR_URL, ICS_CALENDAR_ENTRY_STAGES} from '@/constants'
import {
  BriefingServiceData,
  IcsCalendarFields,
  EmitUpdateInformation,
  EmitUpdateDateAndTime,
  EmitUpdateLocation,
  IcsCalendarConfig,
  ExecuteBriefingServiceAction,
} from '@/types'

const title = 'Create a Calendar Entry'
const description =
  'This is an example text as this dialogue can be used for multiple purposes and can include multiple sentences.'


export default /*@__PURE__*/_defineComponent({
  __name: 'Creation',
  props: {
  briefingServiceData: {
    type: Object as PropType<BriefingServiceData>,
    required: true,
  },
},
  setup(__props) {

const props = __props
const isReady = ref<boolean>(false)

const loading = ref<boolean>(false)
const step = ref<number>(1)
const enableContinueButton = ref<boolean>(false)
const data = ref<IcsCalendarFields>({
  title: '',
  description: '',
  status: 'TENTATIVE',
  start: '',
  end: '',
  alert: 'P0D',
  location: '',
  stamp: '',
  organizer: '',
  uid: '',
})

const onCreate = (): void => {
  getGeneratedIcsUrl()
}
const onClose = (): void => {
  message('rejectBriefingServiceResult', 'closed')
}
const onStepUp = (): void => {
  step.value += 1
}
const onStepDown = (): void => {
  step.value -= 1
}

const onUpdate = (prop: EmitUpdateInformation | EmitUpdateDateAndTime | EmitUpdateLocation) => {
  Object.assign(data.value, prop)
  validation()
}
const validation = (): void => {
  if (step.value >= 1) {
    enableContinueButton.value = !!data.value.title
  }

  if (step.value >= 2) {
    enableContinueButton.value = !!(data.value.start && data.value.end && data.value.alert)
  }

  if (step.value >= 3) {
    enableContinueButton.value = !!(data.value.location && data.value.organizer)
  }
}

// On GUI, we can keep the same UID of event.
// With this option, we update the event in the Calendar app.
const getUid = (): string => {
  if (props.briefingServiceData?.keepUid) {
    return data.value.uid || generateUID()
  }
  return generateUID()
}
const generateUID = (): string => {
  const values: Uint32Array = window.crypto.getRandomValues(new Uint32Array(3))

  return `scp-ics-generator-${values.join('-')}`
}
const message = (command: string, argument: string | ExecuteBriefingServiceAction): void => {
  window.parent.postMessage(
    {
      command: command,
      argument: argument,
    },
    '*'
  )
}

const generateIcsData = (): IcsCalendarConfig => {
  return {
    DTSTART: data.value.start,
    DTEND: data.value.end,
    SUMMARY: data.value.title,
    DESCRIPTION: data.value.description,
    DTSTAMP: data.value.stamp,
    UID: getUid(),
    ALARM: data.value.alert,
    LOCATION: data.value.location,
    STATUS: data.value.status,
    ORGANIZER: data.value.organizer,
  }
}
const getGeneratedIcsUrl = (): void => {
  loading.value = true

  const baseUrl: string = DYNAMIC_ICS_GENERATOR_URL[process.env?.APP_STAGE || 'develop']
  const config: string = JSON.stringify(generateIcsData())
  const url = `${baseUrl}/generate?config=${btoa(config)}`

  loading.value = false

  message('executeBriefingServiceAction', {
    title: data.value.title,
    start: data.value.start,
    url,
  })
}

const fillDataFromUrl = async (url: string) => {
  let config: IcsCalendarConfig

  // old ics files
  if (url.includes('.ics')) {
    const icsData = await (await fetch(url)).text()
    config = fromIcsFile(icsData)
  } else {
    const base64 = url.split('?config=')[1]
    config = JSON.parse(atob(base64))
  }

  data.value.start = config.DTSTART
  data.value.end = config.DTEND
  data.value.title = config.SUMMARY
  data.value.description = config.DESCRIPTION
  data.value.stamp = config.DTSTAMP
  data.value.uid = config.UID
  data.value.alert = config.ALARM
  data.value.location = config.LOCATION
  data.value.status = config.STATUS
  data.value.organizer = config.ORGANIZER
}

const fromIcsFile = (data: string): IcsCalendarConfig => {
  const event: Record<string, string> = {
    ORGANIZER: '',
    STATUS: 'TENTATIVE',
    STAMP: ''
  };

  // each line
  data.split('\n').forEach(line => {
    let [key, value] = line.split(':');

    if (key === '' || value === '') {
      return
    }

    if (value.includes('\r')) {
      value = value.replace('\r', '')
    }

    if (key === 'TRIGGER') {
      event['ALARM'] = value
      return
    }

    if (key in ({} as IcsCalendarConfig)) {
      return
    }

    event[key as string] = value
  });

  return event as IcsCalendarConfig
}

onMounted(async () => {
  if ('url' in props.briefingServiceData && props.briefingServiceData.url) {
    await fillDataFromUrl(props.briefingServiceData.url)
  }

  isReady.value = true
})

watch(step, validation, {
  immediate: true,
  deep: true,
})

return (_ctx: any,_cache: any) => {
  return (isReady.value)
    ? (_openBlock(), _createBlock(_unref(ScpModal), {
        key: 0,
        class: "ics-app-container relative",
        onCloseModal: onClose
      }, {
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(ScpProcessPagination), {
              stages: _unref(ICS_CALENDAR_ENTRY_STAGES),
              "current-stage-index": step.value - 1,
              class: "flex justify-center relative mb-6 mx-auto"
            }, null, 8, ["stages", "current-stage-index"]),
            _createElementVNode("div", { class: "ics-header__title font-open-sans font-bold text-lg primary-dark mb-2" }, _toDisplayString(title)),
            _createElementVNode("div", { class: "ics-header__description font-open-sans text-sm" }, _toDisplayString(description))
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives(_createVNode(Information, {
                title: data.value.title,
                description: data.value.description,
                status: data.value.status,
                onUpdate: onUpdate
              }, null, 8, ["title", "description", "status"]), [
                [_vShow, step.value === 1]
              ]),
              _withDirectives(_createVNode(DateAndTime, {
                start: data.value.start,
                end: data.value.end,
                alert: data.value.alert,
                onUpdate: onUpdate
              }, null, 8, ["start", "end", "alert"]), [
                [_vShow, step.value === 2]
              ]),
              _withDirectives(_createVNode(Location, {
                location: data.value.location,
                organizer: data.value.organizer,
                onUpdate: onUpdate
              }, null, 8, ["location", "organizer"]), [
                [_vShow, step.value === 3]
              ])
            ])
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, [
            (step.value > 1)
              ? (_openBlock(), _createBlock(_unref(ScpButton), {
                  key: 0,
                  class: "ics-action-btn__back",
                  design: "border",
                  onClick: onStepDown
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Back ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (step.value < 3)
              ? (_openBlock(), _createBlock(_unref(ScpButton), {
                  key: 1,
                  class: "ics-action-btn__next",
                  disabled: !enableContinueButton.value || loading.value,
                  onClick: onStepUp
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Next ")
                  ])),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            (step.value === 3)
              ? (_openBlock(), _createBlock(_unref(ScpButton), {
                  key: 2,
                  disabled: !enableContinueButton.value || loading.value,
                  class: "ics-action-btn__create",
                  onClick: onCreate
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Create ")
                  ])),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})