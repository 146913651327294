export const DYNAMIC_ICS_GENERATOR_URL: Record<string, string> = {
  development: 'https://ics.services.develop.innoverse.cloud',
  develop: 'https://ics.services.develop.innoverse.cloud',
  staging: 'https://ics.services.staging.innoverse.cloud',
  prod: 'https://ics.services.innoverse.cloud',
}

export const ICS_CALENDAR_ENTRY_STAGES: Array<Record<string, string>> = [
  {
    title: 'Information',
    indicator: '01',
  },
  {
    title: 'Date & Time',
    indicator: '02',
  },
  {
    title: 'Location',
    indicator: '03',
  },
]
