import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body ics-body-information flex flex-col gap-y-2" }

import {computed, ref} from 'vue'
import {ScpInput, ScpSelect} from '@rmh-media/scp-components'
import {SelectorItem} from '@/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'Information',
  props: {
  title: {
    required: true,
    default: '',
    type: String,
  },
  description: {
    required: true,
    default: '',
    type: String,
  },
  status: {
    required: false,
    default: 'Tentative',
    type: String,
  },
},
  emits: ['update'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const title = ref<string>(props.title)
const description = ref<string>(props.description)
const status = ref<string>(props.status)
const titleError = ref<string>('')
const descriptionError = ref<string>('')

const statusItems: Array<SelectorItem> = [
  {name: 'Tentative', value: 'TENTATIVE'},
  {name: 'Confirmed', value: 'CONFIRMED'},
  {name: 'Cancelled', value: 'CANCELLED'},
]
const statusLabel = ref<string | undefined>(
  statusItems.find((item: SelectorItem) => item.value === status.value)?.value,
)
const label = computed(
  (): Record<string, boolean> => ({
    'text-sm font-open-sans': true,
  }),
)
const field = computed(
  (): Record<string, boolean> => ({
    'grid items-center gap-x-2 border-b-2 border-b-solid pb-2': true,
  }),
)

const validationTitle = (): void => {
  if (!title.value) {
    titleError.value = 'This can not be empty'
  } else {
    titleError.value = ''
  }
}

const validationDescription = (): void => {
  if (!description.value) {
    descriptionError.value = 'This can not be empty'
  } else {
    descriptionError.value = ''
  }
}

const onUpdateTitle = (val: string): void => {
  title.value = val
  validationTitle()
  onUpdate()
}

const onUpdateDescription = (val: string): void => {
  description.value = val
  validationDescription()
  onUpdate()
}

const onUpdateStatus = (val: string): void => {
  status.value = val
  onUpdate()
}

const onUpdate = (): void => {
  emit('update', {
    title: title.value,
    description: description.value,
    status: status.value,
  })
}

onUpdate()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ScpInput), {
      class: "w-full ics-body-information__title",
      label: "Title",
      placeholder: "Title",
      hint: "Mandatory",
      "has-validation": "",
      "error-message": titleError.value,
      "model-value": title.value,
      "onUpdate:modelValue": onUpdateTitle
    }, null, 8, ["error-message", "model-value"]),
    _createVNode(_unref(ScpInput), {
      class: "ics-body-information__description-textfield pt-2 rounded-lg",
      label: "Description",
      placeholder: "Description",
      hint: "Mandatory",
      "has-validation": "",
      "model-value": description.value,
      "error-message": descriptionError.value,
      "onUpdate:modelValue": onUpdateDescription
    }, null, 8, ["model-value", "error-message"]),
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body-information__status", field.value])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(label.value)
      }, "Status", 2),
      _createVNode(_unref(ScpSelect), {
        modelValue: statusLabel.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((statusLabel).value = $event)),
          onUpdateStatus
        ],
        class: "m-0 ics-body-information__status__dropdown",
        items: statusItems
      }, null, 8, ["modelValue"])
    ], 2)
  ]))
}
}

})