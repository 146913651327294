import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import './main.css'
  import { ref, onBeforeUnmount } from 'vue'
  import Creation from '@/components/calendarEntry/Creation.vue'
  import { BriefingServiceData } from '@/types'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  const briefingServiceData = ref<BriefingServiceData|null>(null)

  const handleMessage = (e: MessageEvent) => {
    if (e.data.command === 'briefingServicePostData') {
      briefingServiceData.value = e.data.content
    }
  }

  window.addEventListener('message', handleMessage)

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleMessage)
  })

return (_ctx: any,_cache: any) => {
  return (briefingServiceData.value)
    ? (_openBlock(), _createBlock(Creation, {
        key: 0,
        "briefing-service-data": briefingServiceData.value
      }, null, 8, ["briefing-service-data"]))
    : _createCommentVNode("", true)
}
}

})