<template>
  <ScpModal
    v-if="isReady"
    class="ics-app-container relative"
    @close-modal="onClose"
  >
    <template #body>
      <div class="ics-header">
        <!--  Progress  -->
        <scp-process-pagination
          :stages="ICS_CALENDAR_ENTRY_STAGES"
          :current-stage-index="step - 1"
          class="flex justify-center relative mb-6 mx-auto"
        />

        <div class="ics-header__title font-open-sans font-bold text-lg primary-dark mb-2">
          {{ title }}
        </div>
        <div class="ics-header__description font-open-sans text-sm">
          {{ description }}
        </div>
      </div>

      <div class="ics-container">
        <div class="ics-content mt-6 mb-4 bg-primary-light bg-opacity-30 p-2 rounded-lg">
          <!--  Fields  -->
          <Information
            v-show="step === 1"
            :title="data.title"
            :description="data.description"
            :status="data.status"
            @update="onUpdate"
          />

          <DateAndTime
            v-show="step === 2"
            :start="data.start"
            :end="data.end"
            :alert="data.alert"
            @update="onUpdate"
          />

          <Location
            v-show="step === 3"
            :location="data.location"
            :organizer="data.organizer"
            @update="onUpdate"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <span class="ics-action-btn flex gap-x-2 justify-end">
        <ScpButton
          v-if="step > 1"
          class="ics-action-btn__back"
          design="border"
          @click="onStepDown"
        >
          Back
        </ScpButton>

        <ScpButton
          v-if="step < 3"
          class="ics-action-btn__next"
          :disabled="!enableContinueButton || loading"
          @click="onStepUp"
        >
          Next
        </ScpButton>

        <ScpButton
          v-if="step === 3"
          :disabled="!enableContinueButton || loading"
          class="ics-action-btn__create"
          @click="onCreate"
        >
          Create
        </ScpButton>
      </span>
    </template>
  </ScpModal>
</template>

<script lang="ts" setup>
import {ref, watch, PropType, onMounted} from 'vue'
import {ScpButton, ScpProcessPagination, ScpModal} from '@rmh-media/scp-components'
import Information from '@/components/calendarEntry/Information.vue'
import DateAndTime from '@/components/calendarEntry/DateAndTime.vue'
import Location from '@/components/calendarEntry/Location.vue'
import {DYNAMIC_ICS_GENERATOR_URL, ICS_CALENDAR_ENTRY_STAGES} from '@/constants'
import {
  BriefingServiceData,
  IcsCalendarFields,
  EmitUpdateInformation,
  EmitUpdateDateAndTime,
  EmitUpdateLocation,
  IcsCalendarConfig,
  ExecuteBriefingServiceAction,
} from '@/types'

const props = defineProps({
  briefingServiceData: {
    type: Object as PropType<BriefingServiceData>,
    required: true,
  },
})
const isReady = ref<boolean>(false)

const loading = ref<boolean>(false)
const step = ref<number>(1)
const enableContinueButton = ref<boolean>(false)
const data = ref<IcsCalendarFields>({
  title: '',
  description: '',
  status: 'TENTATIVE',
  start: '',
  end: '',
  alert: 'P0D',
  location: '',
  stamp: '',
  organizer: '',
  uid: '',
})

const title = 'Create a Calendar Entry'
const description =
  'This is an example text as this dialogue can be used for multiple purposes and can include multiple sentences.'

const onCreate = (): void => {
  getGeneratedIcsUrl()
}
const onClose = (): void => {
  message('rejectBriefingServiceResult', 'closed')
}
const onStepUp = (): void => {
  step.value += 1
}
const onStepDown = (): void => {
  step.value -= 1
}

const onUpdate = (prop: EmitUpdateInformation | EmitUpdateDateAndTime | EmitUpdateLocation) => {
  Object.assign(data.value, prop)
  validation()
}
const validation = (): void => {
  if (step.value >= 1) {
    enableContinueButton.value = !!data.value.title
  }

  if (step.value >= 2) {
    enableContinueButton.value = !!(data.value.start && data.value.end && data.value.alert)
  }

  if (step.value >= 3) {
    enableContinueButton.value = !!(data.value.location && data.value.organizer)
  }
}

// On GUI, we can keep the same UID of event.
// With this option, we update the event in the Calendar app.
const getUid = (): string => {
  if (props.briefingServiceData?.keepUid) {
    return data.value.uid || generateUID()
  }
  return generateUID()
}
const generateUID = (): string => {
  const values: Uint32Array = window.crypto.getRandomValues(new Uint32Array(3))

  return `scp-ics-generator-${values.join('-')}`
}
const message = (command: string, argument: string | ExecuteBriefingServiceAction): void => {
  window.parent.postMessage(
    {
      command: command,
      argument: argument,
    },
    '*'
  )
}

const generateIcsData = (): IcsCalendarConfig => {
  return {
    DTSTART: data.value.start,
    DTEND: data.value.end,
    SUMMARY: data.value.title,
    DESCRIPTION: data.value.description,
    DTSTAMP: data.value.stamp,
    UID: getUid(),
    ALARM: data.value.alert,
    LOCATION: data.value.location,
    STATUS: data.value.status,
    ORGANIZER: data.value.organizer,
  }
}
const getGeneratedIcsUrl = (): void => {
  loading.value = true

  const baseUrl: string = DYNAMIC_ICS_GENERATOR_URL[process.env?.APP_STAGE || 'develop']
  const config: string = JSON.stringify(generateIcsData())
  const url = `${baseUrl}/generate?config=${btoa(config)}`

  loading.value = false

  message('executeBriefingServiceAction', {
    title: data.value.title,
    start: data.value.start,
    url,
  })
}

const fillDataFromUrl = async (url: string) => {
  let config: IcsCalendarConfig

  // old ics files
  if (url.includes('.ics')) {
    const icsData = await (await fetch(url)).text()
    config = fromIcsFile(icsData)
  } else {
    const base64 = url.split('?config=')[1]
    config = JSON.parse(atob(base64))
  }

  data.value.start = config.DTSTART
  data.value.end = config.DTEND
  data.value.title = config.SUMMARY
  data.value.description = config.DESCRIPTION
  data.value.stamp = config.DTSTAMP
  data.value.uid = config.UID
  data.value.alert = config.ALARM
  data.value.location = config.LOCATION
  data.value.status = config.STATUS
  data.value.organizer = config.ORGANIZER
}

const fromIcsFile = (data: string): IcsCalendarConfig => {
  const event: Record<string, string> = {
    ORGANIZER: '',
    STATUS: 'TENTATIVE',
    STAMP: ''
  };

  // each line
  data.split('\n').forEach(line => {
    let [key, value] = line.split(':');

    if (key === '' || value === '') {
      return
    }

    if (value.includes('\r')) {
      value = value.replace('\r', '')
    }

    if (key === 'TRIGGER') {
      event['ALARM'] = value
      return
    }

    if (key in ({} as IcsCalendarConfig)) {
      return
    }

    event[key as string] = value
  });

  return event as IcsCalendarConfig
}

onMounted(async () => {
  if ('url' in props.briefingServiceData && props.briefingServiceData.url) {
    await fillDataFromUrl(props.briefingServiceData.url)
  }

  isReady.value = true
})

watch(step, validation, {
  immediate: true,
  deep: true,
})
</script>

<style>
.scp-process-pagination {
  width: 300px !important;
}

.ics-app-container {
  > .relative {
    @apply rounded-none;
    @apply w-max h-full;
    min-width: 440px;
  }

  .ics-action-btn {
    button {
      width: 90px;
    }
  }

  .ics-container {
    height: 360px;
  }

  /* forcing light theme */
  > .bg-primary-dark,
  .scp-select > div > div,
  input {
    background-color: #FFFFFF;
  }

  input,
  .scp-select span,
  label {
    @apply text-primary-dark;
  }

  input::placeholder {
    @apply opacity-20;
  }

  .error label {
    @apply text-action-red;
  }

  .scp-select__panel > div {
    @apply hover:bg-primary-lightest;

    &.bg-action-blue-light {
      @apply bg-primary-light;
    }
  }

  [data-cy="select"] .ri-arrow-down-s-line {
    @apply text-primary-dark
  }
  [data-cy="dropdown"] .scp-select__panel-item-icon {
    @apply text-primary;
  }
}
</style>
