<template>
  <div class="ics-body ics-body-dates flex flex-col gap-y-2">
    <div
      class="ics-body-dates__start"
      :class="field"
    >
      <span :class="label">Start</span>
      <ScpInput
        class="ics-body-dates__start__date w-full"
        type="date"
        :model-value="start"
        :placeholder="currentDate"
        :class="{ error: isError }"
        @update:model-value="onUpdateStartDate"
      />
      <ScpInput
        v-model="startTime"
        class="ics-body-dates__start__time w-full"
        :class="{ error: isError }"
        @update:model-value="onUpdateStartTime"
      />
    </div>

    <div
      class="ics-body-dates__end"
      :class="field"
    >
      <span :class="label">End</span>
      <ScpInput
        class="ics-body-dates__end__date w-full"
        type="date"
        :model-value="end"
        :placeholder="currentDate"
        :class="{ error: isError }"
        @update:model-value="onUpdateEndDate"
      />
      <ScpInput
        v-model="endTime"
        class="ics-body-dates__end__time w-full"
        :class="{ error: isError }"
        @update:model-value="onUpdateEndTime"
      />
    </div>

    <div
      class="ics-body-dates__alert"
      :class="field"
    >
      <span :class="label">Alert</span>
      <ScpSelect
        v-model="alertLabel"
        class="m-0 ics-body-dates__alert__dropdown"
        :items="alertItems"
        @update:model-value="onUpdateAlert"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref, computed, onMounted} from 'vue'
import {ScpInput, ScpSelect} from '@rmh-media/scp-components'
import moment from 'moment'
import {SelectorItem} from '@/types'

window.moment = moment

const props = defineProps({
  start: {
    required: true,
    default: '',
    type: String,
  },
  end: {
    required: true,
    default: '',
    type: String,
  },
  alert: {
    required: true,
    default: '0',
    type: String,
  },
})
const emit = defineEmits(['update'])

const label = computed(
  (): Record<string, boolean> => ({
    'font-semibold text-lg label font-open-sans': true,
  }),
)
const field = computed(
  (): Record<string, boolean> => ({
    'grid items-center gap-x-2 border-b-2 border-b-solid border-white pb-2': true,
  }),
)

const start = ref<string>(props.start)
const startTime = ref<string>('10:00')
const end = ref<string>(props.end)
const endTime = ref<string>('10:00')
const isError = ref<boolean>(false)
const alert = ref<string>(props.alert)
const now: moment.Moment = moment().utc()
const currentDate: string = now.toISOString().split('T')[0].toString()
const alertItems: Array<SelectorItem> = [
  {name: 'Without', value: 'P0D'},
  {name: '1 Day before', value: '-P1D'},
  {name: '2 Days before', value: '-P2D'},
  {name: '3 Days before', value: '-P3D'},
  {name: '4 Days before', value: '-P4D'},
  {name: '1 Week before', value: '-P7D'},
  {name: '2 Weeks before', value: '-P14D'},
]
const alertLabel = ref<string | undefined>(
  alertItems.find((item: SelectorItem) => item.value === alert.value)?.value,
)

const setIndividualTime = (date: string, time: string): string => {
  let times = time.split(':')
  if (!date || !time.includes(':') || times[0].length < 2 || times[1].length < 2) {
    return ''
  }

  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])
  const d = moment(date).hour(hours).minute(minutes)
  return d.isValid() ? d.format('YYYY-MM-DDTHH:mm') : date
}

const validData = (): boolean => {
  return !!(
    start.value &&
    moment(start.value).isValid() &&
    end.value &&
    moment(end.value).isValid() &&
    alert.value
  )
}

const onUpdateStartDate = (val: Date): void => {
  const value = setIndividualTime(moment(val).toISOString(), startTime.value)
  start.value = value

  if (!alert.value) {
    alert.value = value
  }

  onUpdate()
}

const onUpdateEndDate = (val: Date): void => {
  end.value = setIndividualTime(moment(val).toISOString(), endTime.value)
  onUpdate()
}

const onUpdateStartTime = (val: string): void => {
  startTime.value = val
  const date = setIndividualTime(start.value, val)

  if (!date) {
    showError(true)
    emptyUpdate()
    return
  }

  start.value = date
  onUpdate()
}

const onUpdateEndTime = (val: string): void => {
  endTime.value = val
  const date = setIndividualTime(end.value, val)

  if (!date) {
    showError(true)
    emptyUpdate()
    return
  }

  end.value = date
  onUpdate()
}

const onUpdateAlert = (val: string): void => {
  alert.value = val
  onUpdate()
}

const showError = (force = false): boolean => {
  if (force) {
    isError.value = true
    return isError.value
  }

  if (!start.value && !startTime.value && !end.value && !endTime.value) {
    return false
  }

  const s = moment(start.value)
  const e = moment(end.value)

  isError.value = s.diff(e) >= 0
  return isError.value
}

const emptyUpdate = (): void => {
  emit('update', {
    start: '',
    end: '',
    alert: '',
  })
}

const onUpdate = (): void => {
  if (showError()) {
    emptyUpdate()
    return
  }

  if (!validData()) {
    return
  }

  emit('update', {
    start: moment(start.value).utc().format(),
    end: moment(end.value).utc().format(),
    stamp: now.format(),
    alert: alert.value,
  })
}

if (props.start) {
  start.value = moment(start.value).local().format()
  startTime.value = start.value.split('T')[1].slice(0, 5)
  onUpdate()
}

if (props.end) {
  end.value = moment(end.value).local().format()
  endTime.value = end.value.split('T')[1].slice(0, 5)
  onUpdate()
}

onMounted((): void => {
  // Set Time fields to max 5 (example: 12:15)
  document
    .querySelectorAll('.ics-body-dates__start__time input, .ics-body-dates__end__time input')
    .forEach(el => {
      if (el instanceof HTMLInputElement) {
        el.maxLength = 5
      }
    })
})
</script>

<style lang="scss">
.ics-body-dates {
  &__start,
  &__end {
    grid-template-areas: 'label . date time';
    grid-template-columns: 100px auto 150px 70px;

    .label {
      grid-area: label;
    }

    &__date {
      grid-area: date;
    }

    &__time {
      grid-area: time;
    }

    .error {
      input {
        @apply border-action-red text-action-red;
      }

      svg * {
        @apply fill-current text-action-red;
      }
    }
  }

  &__alert {
    grid-template-areas: 'label . dropdown';
    grid-template-columns: 100px auto 228px;

    .label {
      grid-area: label;
    }

    &__dropdown {
      grid-area: dropdown;

      [data-cy='select'] {
        background-color: transparent !important;
      }
    }

    [data-cy='select'] {
      @apply justify-end;
      @apply bg-transparent;
      @apply border-transparent;
      @apply shadow-none;
      @apply py-1;

      [data-cy='value'] {
        @apply text-lg font-light;
        @apply font-open-sans;
        @apply mr-2;
      }
    }

    [data-cy='dropdown'] {
      @apply p-2;

      [data-cy='items'] {
        @apply rounded;
      }
    }
  }

  .scp-input__date-picker {
    .mx-icon-calendar {
      --tw-text-opacity: inherit !important;
    }

    input {
      color: rgba(43,49,81,var(--tw-text-opacity)) !important;
      background-color: #FFFFFF !important;

      &::placeholder {
        @apply text-primary-dark;
      }
    }
  }

  > :last-child {
    border: none;
  }
}
</style>
