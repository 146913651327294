import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body ics-body-dates flex flex-col gap-y-2" }

import {ref, computed, onMounted} from 'vue'
import {ScpInput, ScpSelect} from '@rmh-media/scp-components'
import moment from 'moment'
import {SelectorItem} from '@/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateAndTime',
  props: {
  start: {
    required: true,
    default: '',
    type: String,
  },
  end: {
    required: true,
    default: '',
    type: String,
  },
  alert: {
    required: true,
    default: '0',
    type: String,
  },
},
  emits: ['update'],
  setup(__props, { emit: __emit }) {

window.moment = moment

const props = __props
const emit = __emit

const label = computed(
  (): Record<string, boolean> => ({
    'font-semibold text-lg label font-open-sans': true,
  }),
)
const field = computed(
  (): Record<string, boolean> => ({
    'grid items-center gap-x-2 border-b-2 border-b-solid border-white pb-2': true,
  }),
)

const start = ref<string>(props.start)
const startTime = ref<string>('10:00')
const end = ref<string>(props.end)
const endTime = ref<string>('10:00')
const isError = ref<boolean>(false)
const alert = ref<string>(props.alert)
const now: moment.Moment = moment().utc()
const currentDate: string = now.toISOString().split('T')[0].toString()
const alertItems: Array<SelectorItem> = [
  {name: 'Without', value: 'P0D'},
  {name: '1 Day before', value: '-P1D'},
  {name: '2 Days before', value: '-P2D'},
  {name: '3 Days before', value: '-P3D'},
  {name: '4 Days before', value: '-P4D'},
  {name: '1 Week before', value: '-P7D'},
  {name: '2 Weeks before', value: '-P14D'},
]
const alertLabel = ref<string | undefined>(
  alertItems.find((item: SelectorItem) => item.value === alert.value)?.value,
)

const setIndividualTime = (date: string, time: string): string => {
  let times = time.split(':')
  if (!date || !time.includes(':') || times[0].length < 2 || times[1].length < 2) {
    return ''
  }

  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])
  const d = moment(date).hour(hours).minute(minutes)
  return d.isValid() ? d.format('YYYY-MM-DDTHH:mm') : date
}

const validData = (): boolean => {
  return !!(
    start.value &&
    moment(start.value).isValid() &&
    end.value &&
    moment(end.value).isValid() &&
    alert.value
  )
}

const onUpdateStartDate = (val: Date): void => {
  const value = setIndividualTime(moment(val).toISOString(), startTime.value)
  start.value = value

  if (!alert.value) {
    alert.value = value
  }

  onUpdate()
}

const onUpdateEndDate = (val: Date): void => {
  end.value = setIndividualTime(moment(val).toISOString(), endTime.value)
  onUpdate()
}

const onUpdateStartTime = (val: string): void => {
  startTime.value = val
  const date = setIndividualTime(start.value, val)

  if (!date) {
    showError(true)
    emptyUpdate()
    return
  }

  start.value = date
  onUpdate()
}

const onUpdateEndTime = (val: string): void => {
  endTime.value = val
  const date = setIndividualTime(end.value, val)

  if (!date) {
    showError(true)
    emptyUpdate()
    return
  }

  end.value = date
  onUpdate()
}

const onUpdateAlert = (val: string): void => {
  alert.value = val
  onUpdate()
}

const showError = (force = false): boolean => {
  if (force) {
    isError.value = true
    return isError.value
  }

  if (!start.value && !startTime.value && !end.value && !endTime.value) {
    return false
  }

  const s = moment(start.value)
  const e = moment(end.value)

  isError.value = s.diff(e) >= 0
  return isError.value
}

const emptyUpdate = (): void => {
  emit('update', {
    start: '',
    end: '',
    alert: '',
  })
}

const onUpdate = (): void => {
  if (showError()) {
    emptyUpdate()
    return
  }

  if (!validData()) {
    return
  }

  emit('update', {
    start: moment(start.value).utc().format(),
    end: moment(end.value).utc().format(),
    stamp: now.format(),
    alert: alert.value,
  })
}

if (props.start) {
  start.value = moment(start.value).local().format()
  startTime.value = start.value.split('T')[1].slice(0, 5)
  onUpdate()
}

if (props.end) {
  end.value = moment(end.value).local().format()
  endTime.value = end.value.split('T')[1].slice(0, 5)
  onUpdate()
}

onMounted((): void => {
  // Set Time fields to max 5 (example: 12:15)
  document
    .querySelectorAll('.ics-body-dates__start__time input, .ics-body-dates__end__time input')
    .forEach(el => {
      if (el instanceof HTMLInputElement) {
        el.maxLength = 5
      }
    })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body-dates__start", field.value])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(label.value)
      }, "Start", 2),
      _createVNode(_unref(ScpInput), {
        class: _normalizeClass(["ics-body-dates__start__date w-full", { error: isError.value }]),
        type: "date",
        "model-value": start.value,
        placeholder: _unref(currentDate),
        "onUpdate:modelValue": onUpdateStartDate
      }, null, 8, ["model-value", "placeholder", "class"]),
      _createVNode(_unref(ScpInput), {
        modelValue: startTime.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((startTime).value = $event)),
          onUpdateStartTime
        ],
        class: _normalizeClass(["ics-body-dates__start__time w-full", { error: isError.value }])
      }, null, 8, ["modelValue", "class"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body-dates__end", field.value])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(label.value)
      }, "End", 2),
      _createVNode(_unref(ScpInput), {
        class: _normalizeClass(["ics-body-dates__end__date w-full", { error: isError.value }]),
        type: "date",
        "model-value": end.value,
        placeholder: _unref(currentDate),
        "onUpdate:modelValue": onUpdateEndDate
      }, null, 8, ["model-value", "placeholder", "class"]),
      _createVNode(_unref(ScpInput), {
        modelValue: endTime.value,
        "onUpdate:modelValue": [
          _cache[1] || (_cache[1] = ($event: any) => ((endTime).value = $event)),
          onUpdateEndTime
        ],
        class: _normalizeClass(["ics-body-dates__end__time w-full", { error: isError.value }])
      }, null, 8, ["modelValue", "class"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["ics-body-dates__alert", field.value])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(label.value)
      }, "Alert", 2),
      _createVNode(_unref(ScpSelect), {
        modelValue: alertLabel.value,
        "onUpdate:modelValue": [
          _cache[2] || (_cache[2] = ($event: any) => ((alertLabel).value = $event)),
          onUpdateAlert
        ],
        class: "m-0 ics-body-dates__alert__dropdown",
        items: alertItems
      }, null, 8, ["modelValue"])
    ], 2)
  ]))
}
}

})