<template>
  <div class="ics-body ics-body-location flex flex-col gap-y-2">
    <scp-input
      class="w-full ics-body-location__title"
      label="Location"
      placeholder="Location"
      hint="Mandatory"
      has-validation
      :model-value="location"
      @update:model-value="onUpdateLocation"
    />

    <scp-input
      class="w-full ics-body-location__organizer"
      label="Organizer"
      placeholder="Organizer"
      hint="Mandatory"
      has-validation
      :model-value="organizer"
      @update:model-value="onUpdateOrganizer"
    />
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue'
  import { ScpInput } from '@rmh-media/scp-components'

  const props = defineProps({
    location: {
      required: true,
      default: '',
      type: String,
    },
    organizer: {
      required: true,
      default: '',
      type: String,
    },
  })
  const emit = defineEmits(['update'])

  const location = ref<string>(props.location)
  const organizer = ref<string>(props.organizer)

  const onUpdateLocation = (val: string): void => {
    location.value = val
    onUpdate()
  }

  const onUpdateOrganizer = (val: string): void => {
    organizer.value = val
    onUpdate()
  }

  const onUpdate = (): void => {
    emit('update', {
      location: location.value,
      organizer: organizer.value,
    })
  }

  onUpdate()
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .ics-body-location {
    .scp-input {
      @apply text-sm;

      .hint {
        @apply opacity-50;
      }

      &__label {
        @apply text-sm mb-2;
      }
    }

    .scp-text-field {
      &__label {
        @apply text-sm;
      }
    }
  }
</style>
